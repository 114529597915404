<template>
    <div class="search plan-list-page">
        <div class="advice-box right uf uf-ver">
            <div class="title uf uf-ac uf-pc">
                <i class="iconfont icon-remen mr-2"></i>
                <span>热门方案</span>
            </div>
            <div class="con-box uf-f1 scroll">
                <div class="c-list pr" v-for="item in hotList" :key="item.id" @click="chooseProduct(item)">
                    <div class="jiaobiao pr">
                        <div class="txt">热门</div>
                        <i class="iconfont icon-jiaobiao red"></i>
                    </div>
                    <div class="name f15 fwb">{{ item.serviceName }}</div>
                    <div class="mt-2 f14 op-07">所属行业：{{ item.itemName }}</div>
                    <div class="mt-1 f14"><span class="op-07">服务商：</span><span class="c-base">{{ item.enterpriseName }}</span></div>
                </div>
            </div>
        </div>
        <div class="trades-navs uf uf-ver uf-pc">
            <div class="tn-list uf-f1" @click="changeTrade('all')" :class="{on: curTradeIndex === -1}">
                <!--        <el-image class="wi100 hi100" :src="item.bg" fit="cover"></el-image>-->
                <div class="tn-dec uf uf-ac">
                    <i class="iconfont icon-all"></i>
                    <div class="tn-name">全部</div>
                </div>
            </div>
            <div class="tn-list uf-f1" v-for="(item, index) in trades" :key="index" :class="{on: index === curTradeIndex}" @click="changeTrade(index)">
                <!--        <el-image class="wi100 hi100" :src="item.bg" fit="cover"></el-image>-->
                <div class="tn-dec uf uf-ac">
                    <i :class="`iconfont ${item.icon}`"></i>
                    <div class="tn-name">{{ item.name }}</div>
                </div>
            </div>
        </div>
        <div class="content-box mt-3 mb-3 pr" v-loading = "loading" element-loading-background="rgba(0,0,0,0)">
            <div class="p-3" style="width: 100%;background: #ffffff;border-bottom: 1px solid #eee; position: sticky;top: 85px;z-index: 98">
                <el-page-header @back="goback()">
                    <span slot="content" style="font-weight:bold">全部方案</span>
                </el-page-header>
            </div>
            <div class="plan-search uf uf-pc">
                <div class="wi50 uf uf-pc pr">
                    <el-input placeholder="请输入搜索内容" class="mr-2" v-model="dataForm.serviceName" @keyup.enter.native="getDataList(true)"></el-input>
                    <el-button type="primary" class="button" icon="el-icon-search" @click="getDataList(true)">搜索</el-button>
                </div>
            </div>
            <div class="plan-box tal">
                <div v-if="dataList.length > 0" class="uf uf-pj fwrap-w">
                    <!--          <service-card class="100%" :service="comItem" @showDetail="chooseProduct(comItem)"/>-->
                    <service-card class="card-li" v-for="item in dataList" :service="item" @showDetail="chooseProduct(item)" :key="item.id"/>
                    <!--          <div class="zhanwei" style="width: 430px"></div>-->
                    <!--          <div class="zhanwei" style="width: 430px"></div>-->
                </div>
                <template v-else>
                    <div class="tac wi100" style="height: 300px">
                        <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
                        <div class="f14" style="color: #909399">暂无数据</div>
                    </div>
                </template>

            </div>
            <div class="tac wi100 mt-4 pagination">
                <el-pagination
                    background
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50]"
                    :page-size="pageSize"
                    :total="totalCount"
                    layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>
        <detail-dialog v-if="detailVisible" ref="detail"></detail-dialog>
    </div>
</template>

<script>
import {checkIsNull} from '../../utils'
import routerTransfer from '../../utils/routerTransfer'
import ServiceCard from '../components/service-card-plan.vue'
import DetailDialog from '@/views/plan/detail-dialog.vue'

export default {
    name: 'search',
    components: {
        ServiceCard, DetailDialog
    },
    data () {
        return {
            detailVisible: false,
            planSearch: '',
            dataList: [],
            hotList: [],
            curTradeIndex: -1,
            trades: [
                {
                    itemCode: '01',
                    name: '生物医药行业',
                    bg: require('../../assets/img/index/shengWuYiYao.jpg'),
                    icon: 'icon-shengwuyiyao',
                    color: '#409EFF',
                    color2: '#9FDCFF',
                    router: 'index',
                    sence: '基于超算的药物筛选和研发。提供软件和算力支持的方式加快药物筛选、基因组学检测分析等进程。让以往需以月乃至年为计的运算、检测时间被缩短到几天，大大缩短药物研制时间，快速进入临床治疗。服务平台可基于超算中心的算力支撑，对接药物研发企业的研发能力和药物生产企业的生产能力。',
                    tech: '基于超算的数字化服务；面向制造服务生态体系的数据交换方法'
                },
                {
                    itemCode: '02',
                    name: '机械装备行业',
                    bg: require('../../assets/img/index/jiXie.jpg'),
                    icon: 'icon-jixie',
                    color: '#ff6900',
                    color2: '#ffbd73',
                    router: 'index',
                    sence: '提供装备的智能运维服务。基于智能传感设备和智能边缘计算网关等实现对装备的实时监测与预警，提供从边缘计算到工业云平台端到端的服务，达到装备安全长周期运行维护的目的。使机械装备生产企业实现服务型制造转型。',
                    tech: '基于AI+5G+IoT的云边端架构设备远程运维与故障诊断；工业海量数据管理与处理技术；面向全生命周期的制造服务数据治理。'
                },
                {
                    itemCode: '03',
                    name: '汽车行业',
                    bg: require('../../assets/img/index/qiChe.jpg'),
                    icon: 'icon-qiche',
                    color: '#008638',
                    color2: '#9cffd2',
                    router: 'index',
                    sence: '商用车全生命周期服务平台。例如陕西汽车“车轮滚滚”O2O服务平台，以整车产品为基础，在持续提升基本售后服务的同时，开展融资租赁、经营性租赁、商业保险、保险经纪、车联网数据服务等一系列增值服务业务。',
                    tech: '产品服务系统设计与优化技术；制造服务智能交易技术；面向制造服务生态体系的数据交换方法。'
                },
                {
                    itemCode: '04',
                    name: '厨电行业',
                    bg: require('../../assets/img/index/chuFang.jpg'),
                    icon: 'icon-youyanji',
                    color: '#9200e5',
                    color2: '#d19fff',
                    router: 'index',
                    sence: '提供基于传感和人工智能技术的烹饪曲线模型。例如老板电器-中式烹饪曲线的建立。已开始，通过AI人工智能精准追踪时间和温度两大核心指标，掌控每道菜式的烹饪节奏，建设中式烹饪曲线数据库，形成烹饪曲线优化，模型进一步推动厨电的智能升级，提升用户体验。',
                    tech: '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；制造服务数据的安全隐私保护。'
                },
                {
                    itemCode: '05',
                    name: '纺织行业',
                    bg: require('../../assets/img/index/fangZhi.jpg'),
                    icon: 'icon-duanxiujikouyifu',
                    color: '#c5a500',
                    color2: '#c9c579',
                    router: 'index',
                    sence: '提供纺织服装全产业敏捷供应链管理，实现产业链上下游敏捷协同。上游由供应商乃至供应商的供应商组成的企业合作伙伴关系，下游由生产商、分销商、零售商、终端消费者组成的用户关系。面向供应链的统一管理，促进信息流、资金流、物流的协同整合，提升供应链整体的效率和效益。例如阿里云IoT技术帮助企业实现实时控制和智能制造，大幅提高行业供应链能力，助力服装行业整体效率水平提升。5G MES技术应用于服装行业，推动工厂侧云、边、端的数据数据闭环，最终实现服装行业产业链透明互联。秒优等合作伙伴依托服装行业工业互联网平台，形成了可快速复制的智能制造创新模式，数据驱动整个服装生产流程，通过系统集成、精益管理、IoT技术、智能排产、智能在线调度、数字化绩效手段，将原本大批量生产模式转化为连续的、小批量、个性化、高效益的生产模式，真正做到快速精准去库存，实现小单快返的终极目标。',
                    tech: '网络驱动的制造产品服务化设计；制造服务智能交易技术。'
                },
                {
                    itemCode: '06',
                    name: '包装行业',
                    bg: require('../../assets/img/index/baoZhuang.jpg'),
                    icon: 'icon-wuliaobaozhuangdan',
                    color: '#409EFF',
                    color2: '#9FDCFF',
                    router: 'index',
                    sence: '提供基于AI+AR的包装定制化设计服务。提供具有专业包装设计能力的人工智能和增强现实服务，客户只需在包装商城上输入包装设计需求，就会自动快速提供若干种方案供其选择，并且能呈现最终的三维立体、增强现实效果。具有强大的学习能力，学习用户习惯、市场偏好等等，这些都将成为它的“知识储备”，更好地为用户提供包装设计服务。',
                    tech: '基于数字孪生和工业互联网的数字化服务技术；产品与服务系统设计优化方法。'
                },
                {
                    itemCode: '07',
                    name: '家具行业',
                    bg: require('../../assets/img/index/jiaJu.jpg'),
                    icon: 'icon-shafa',
                    color: '#ff6900',
                    color2: '#ffbd73',
                    router: 'index',
                    sence: '提供易简大规模个性化定制服务模式，实现“先设计，再销售，后生产”。破解定制家居生产的周期长、出错率高、品质差和规模生产难的难题，实现了规模个性化设计、大规模个性化定制与大规模个性化服务，有效把制造与服务融合，为消费者提供一站式的全新的定制家居消费体验。',
                    tech: '基于数字孪生和工业互联网的数字化服务技术；基于人工智能技术的制造和服务深度融合。'
                },
                {
                    itemCode: '08',
                    name: '生鲜食品行业',
                    bg: require('../../assets/img/index/shengXian.jpg'),
                    icon: 'icon-shuiguo',
                    color: '#008638',
                    color2: '#9cffd2',
                    router: 'index',
                    sence: '提供冷链物流全过程、全周期服务，实现冷链全过程监测与追溯，检验检疫服务。例如阿里的盒马鲜生。盒马运用大数据、移动互联、智能物联网、自动化等技术及先进设备，实现人、货、场三者之间的最优化匹配，平台拥有从供应链、仓储到配送的完整物流体系。',
                    tech: '网络驱动的产品服务化设计；服务数据交换方法、定价模型和智能交易技术。'
                },
                {
                    itemCode: '09',
                    name: '电梯行业',
                    bg: require('../../assets/img/index/dianTi.jpg'),
                    icon: 'icon-dianti-xiantiao',
                    color: '#9200e5',
                    color2: '#d19fff',
                    router: 'index',
                    sence: '提供电梯设备预测性维护、设备全生命周期管理以及提供可信的透明监管和全链路的安全保障。例如，通过工业互联网的规则引擎与阿里云其他云产品打通，实现采集+计算+存储+AI+可信的全栈服务，充分挖掘物联网数据的价值，实现连接到智能的跨越；通过构建数据平台，提供设备预测与分析统计服务，可以实现电梯设备预测性维护、设备全生命周期管理以及提供可信的透明监管和全链路的安全保障。基于可信电梯物联网平台实施的垂直电梯远程监测解决方案，采用不触碰电梯本身的安装方式，快速适配老旧电梯，采集上报各种数据，比如电梯运行状态信息，是否有人信息、所处楼层信息、实时故障等信息，接入到远程监控系统。结合后台数据为客户提供故障分析服务以及故障处理救援服务、智能维护服务等。',
                    tech: '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；全生命周期的制造服务数据治理。'
                },
                {
                    itemCode: '10',
                    name: '精密模具行业',
                    bg: require('../../assets/img/index/muJu.jpg'),
                    icon: 'icon-yewumoban',
                    color: '#409EFF',
                    color2: '#9FDCFF',
                    router: 'index',
                    sence: '采用“平台+小微”方式，实现“共享制造”。例如深圳市五鑫科技的“Mould Lao众创空间”，以赋能的方式帮助平台内小微企业及各个技术创客实现从被动劳动变成自主竞价模式下的技能共享。小微企业的每笔订单，以内部市场竞标的方式由小微及创客自主报价，相互竞标。竞标成功后，平台通过共享制造系统对每个创客的工作进度和制造品质进行实时跟踪，他们完成一天的工作后可以立即扫码结算。平台将大量小微企业整合到一起，通过大数据、人工智能等技术，使供需之间能够实现智能化高效匹配。',
                    tech: '服务数据交换方法、定价模型和智能交易技术；网络驱动的协同制造技术。'
                }
            ],
            visible: false,
            maxLevel: 1,
            expandOption: {},
            serviceItemChoose: {},
            serviceItemList: [],
            itemDataList: {},
            comItem: {
                id: '1',
                serviceName: '微生物生物基因组测序组件',
                // serviceInfo: '基因测序是一种新型基因检测技术，能够从血液或唾液中分析测定基因全序列，预测罹患多种疾病的可能性，个体的行为特征及行为合理。基因测序技术能锁定个人病变基因，提前预防和治疗。',
                itemName: '基因测序是一种新型基因检测技术，能够从血液或唾液中分析测定基因全序列，预测罹患多种疾病的可能性，个体的行为特征及行为合理。基因测序技术能锁定个人病变基因，提前预防和治疗。',
                enterpriseName: '山东启明科技有限公司',
            },
            dataForm: {
                serviceName: '',
                itemCode: '',
                sortType: '',
                serviceMethod: '0',
                orgCode: '',
                priceLow: null,
                priceHigh: null
            },
            loading: false,
            pageIndex: 1,
            pageSize: 20,
            totalCount: 0,
            totalPage: 0,
            indexLen: {
                1: {len: 2, title: '行业分类'},
                2: {len: 4, title: '二级目录'},
                3: {len: 6, title: '三级目录'},
                4: {len: 8, title: '四级目录'},
                5: {len: 12, title: '五级目录'}
            },
            serviceMethodDict: [],
            priceEnterVisible: false
        }
    },
    computed: {
        keepAliveArr: {
            get () { return this.$store.state.common.keepAliveArray},
            set (arr) {this.$store.commit('common/updateKeepAliveArray',arr)}
        }
    },
    created () {
        this.$getDictList('SERVICE_METHOD', (dict) => {
            this.serviceMethodDict = dict.SERVICE_METHOD
        })
        this.dataForm.itemCode = this.$route.query.itemCode || ''
        // this.dataForm.serviceName = this.$route.params.serviceName || ''
        this.dataForm.serviceName = this.$route.query.search || ''
        this.curTradeIndex = this.$route.query.trade
        this.getServiceItemMenu()
    },
    mounted () {
        routerTransfer.$on('searchEvent', function (data) {
            this.dataForm.serviceName = data.serviceName
            this.getDataList(true)
        }.bind(this))
        this.getDataList(true)
        this.getReportList()
    },
    beforeRouteLeave (to ,from, next) {
        if(to.name === 'service') {
            this.keepAliveArr = ['search']
        }
        next()
    },
    beforeDestroy () {
        routerTransfer.$off('searchEvent')
    },
    methods: {
        // 热门推荐和今日推荐列表
        getReportList() {
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.REPORTPARAM),
                methods: 'get'
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.hotList = data.data.searchReportHot
                }
            })
        },
        changeTrade (index) {
            if (index === 'all') {
                this.curTradeIndex = -1
                this.dataForm.itemCode = null
            } else {
                this.curTradeIndex = index
                this.dataForm.itemCode = `0${index + 1}`
            }
            this.getDataList(true)

        },
        goback() {
            // //获取缓存
            // let itemCode = localStorage.getItem('itemCode')
            // // this.$router.go(-1)
            // let itemCodeType = this.$route.query.itemCodeType
            // if (itemCodeType==='search') {
            //   this.$router.push({ name: 'search', params: {itemCode: itemCode ? itemCode: null,},meta:{keepAlive: true} })
            // }else if (itemCodeType === 'pay') {
            //   this.$router.push({ name: 'search', params: {itemCode: itemCode ? itemCode: null,},meta:{keepAlive: true} })
            // }else{
            // }
            this.$router.go(-1)
        },
        // 获取服务目录
        getServiceItemMenu() {
            let menu = localStorage.getItem('serviceItemMenu')
            if (!checkIsNull(menu)) {
                let menuObj = JSON.parse(menu)
                this.serviceItemList = menuObj.menu
                this.maxLevel = menuObj.maxLevel || 1
                this.handleExpandOption()
                this.visible = true
                return
            }
            this.$http({
                url:this.$http.adornUrl(this.$api.PUB.MENU),
                method: 'get'
            }).then(({data})=>{
                if (data && data.code === 0) {
                    this.serviceItemList = data.menu.menu
                    this.maxLevel = data.menu.maxLevel || 1
                    localStorage.setItem('serviceItemMenu', JSON.stringify(data.menu))
                    this.handleExpandOption()
                }
                this.visible = true
            }).catch(() => {this.visible = true})
        },
        // 处理服务类别条件栏
        handleExpandOption () {
            for (let i = 0; i < this.maxLevel; i++) {
                this.expandOption['expand' + (i + 1)] = false
                this.serviceItemChoose['itemChoose' + (i + 1)] = {itemCode: 'all', itemName: '全部'}
                this.itemDataList['dataList' + (i + 1)] = []
            }
            this.handleItem()
        },
        handleItem () {
            let itemCode = this.dataForm.itemCode
            // 处理每层选择的itemCode
            for (let i = 1; i <= this.maxLevel; i++) {
                if (!checkIsNull(itemCode) && itemCode.length >= this.indexLen[i].len) {
                    let thisItemCode = itemCode.substring(0, this.indexLen[i].len)
                    if (i === 1) {
                        this.serviceItemChoose['itemChoose' + i] = this.serviceItemList.filter(item => item.itemCode === thisItemCode)[0]
                    } else {
                        this.serviceItemChoose['itemChoose' + i] = this.serviceItemChoose['itemChoose' + (i - 1)].children.filter(item => item.itemCode === thisItemCode)[0]
                    }
                } else {
                    this.serviceItemChoose['itemChoose' + i] = {itemCode: 'all', itemName: '全部'}
                }
            }
            // 处理每层的数据
            for (let i = 1; i <= this.maxLevel; i++) {
                if (i === 1) {
                    this.itemDataList['dataList' + i] = this.$deepClone(this.serviceItemList)
                } else {
                    if (this.serviceItemChoose['itemChoose' + (i - 1)].itemCode !== 'all') {
                        this.itemDataList['dataList' + i] = this.serviceItemChoose['itemChoose' + (i - 1)].children
                    } else {
                        this.itemDataList['dataList' + i] = []
                    }
                }
            }
            this.$forceUpdate()
        },
        // 是否展开所有条件
        switchExpand (index) {
            this.expandOption['expand' + index] = !this.expandOption['expand' + index]
            this.$forceUpdate()
        },
        // 选择服务类别监听
        itemClickListener (index, item) {
            // 选择的全部
            if (index === 1 && item.itemCode === 'all') {
                this.dataForm.itemCode = ''
            } else if (item.itemCode === 'all') {
                this.dataForm.itemCode = this.serviceItemChoose['itemChoose' + (index - 1)].itemCode
            } else {
                if (item.itemCode === '800101') {
                    window.open('http://model.smartquality.cn/')
                    return
                }
                this.dataForm.itemCode = item.itemCode
            }
            this.handleItem()
            this.searchData()
        },
        // 获取产品列表
        getDataList (refresh) {
            if (refresh) {
                this.pageIndex = 1
            }
            this.loading = true
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST),
                methods: 'get',
                params: this.$http.adornParams({
                    // 'page': this.pageIndex,
                    // 'limit': this.pageSize,
                    // 'serviceName': this.dataForm.serviceName || null,
                    // 'itemCode': this.dataForm.itemCode || null ,
                    // 'orgCode': this.dataForm.orgCode || null,
                    // 'serviceMethod': this.dataForm.serviceMethod === 0 ? null : this.dataForm.serviceMethod,
                    // 'sortType': this.dataForm.sortType || null,
                    // 'priceLow': this.dataForm.priceLow || null,
                    // 'priceHigh': this.dataForm.priceHigh || null
                    'startPage': this.pageIndex -1 ,
                    'pageSize': this.pageSize,
                    'keywords': this.dataForm.serviceName || null,
                    'serviceLabel': 'REPORT',
                    'itemCode': this.dataForm.itemCode || null ,
                    'serviceMethod': this.dataForm.serviceMethod,
                    'sortType': this.dataForm.sortType,
                    'priceLow': this.dataForm.priceLow,
                    'priceHigh': this.dataForm.priceHigh
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    // let itemCode = localStorage.setItem('itemCode',this.dataForm.itemCode)
                    // this.dataList = data.page.list
                    // this.totalCount = data.page.totalCount
                    // this.totalPage = data.page.totalPage
                    this.dataList = data.result.list
                    this.totalCount = data.result.total || 0
                    this.totalPage = Math.ceil(data.result.total/this.pageSize) || 1
                } else {
                    this.dataList = []
                    this.totalCount = 0
                    this.totalPage = 1
                }
                this.loading = false
            })
        },
        // 每页数
        sizeChangeHandle (val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList(true)
        },
        // 当前页
        currentChangeHandle (val) {
            this.pageIndex = val
            this.getDataList(false)
        },
        chooseMethod (dictId) {
            this.dataForm.serviceMethod = dictId
            this.searchData()
        },
        searchData () {
            this.$nextTick(() => {
                this.getDataList(true)
            })
        },
        // 选择产品
        chooseProduct (item) {
            // this.detailVisible = true
            // this.$nextTick(() => {
            //     this.$refs['detail'].init(item)
            // })
            let { href } = this.$router.resolve({
                name: 'plan-detail',
                query: {
                    id: item.id
                }
            })
            window.open(href, '_blank')
            // this.
            // let itemCodeType = 'search'
            // this.keepAliveArr = ['search']
            // this.$router.push({name: 'service', query: {id: item.id,itemCodeType:itemCodeType}})
        },
        //控制字数
        ellipsis (value) {
            if (!value) return ''
            if (value.length > 12) {
                return value.slice(0,12) + '...'
            }
            return value
        }
    }
}
</script>

<style scoped lang="scss">
.advice-box {
    position: fixed;
    top: 140px;
    bottom: 60px;
    right: 10px;
    width: 260px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #cecece;
    overflow: hidden;
    z-index: 99999;
    .title {
        line-height: 42px;
        background: #deeffd;
        //border-bottom: 1px solid #c2c2c2;
        .icon-remen {
            font-size: 20px;
            color: #b90000;
        }
        .icon-jinri {
            font-size: 20px;
            color: #ff7300;
        }
    }
    .con-box {
        overflow: auto;
        .c-list {
            background: #fff;
            border: 1px solid transparent;
            border-bottom: 1px solid #eeeeee;
            //box-shadow: 0 0 10px rgba(0,0,0,.1);
            padding: 15px 10px;
            //border: 1px solid #bbf7ff;
            cursor: pointer;
            overflow: hidden;
            &:hover {
                border: 1px solid #b6b6b6;
                box-shadow: 0 0 15px rgba(0,0,0,.2);
            }
            .jiaobiao {
                position: absolute;
                right: -3px;
                top: -3px;
                color: #fff;
                .txt {
                    position: absolute;
                    font-size: 12px;
                    right: 3px;
                    top: 6px;
                    transform: rotate(45deg);
                }
                .red {
                    color: #ff6200;
                }
                .iconfont {
                    font-size: 46px;
                }
            }
        }
    }
}
.plan-list-page {
    //background: #fff;
    min-height: 83vh;
    background: url("../../assets/img/img04.jpg");
    background-size: 100% auto;
}
.card-li {
    width: 48%;
    height: 180px;
    margin-bottom: 10px;
}
.plan-search {
    position: sticky;
    top: 90px;
    z-index: 99;
    pointer-events: none;
    div {
        pointer-events: all;
        //box-shadow: 0 5px 40px 8px rgb(0 0 0 / 10%);
        ::v-deep .el-input__inner {
            height: 46px;
            font-size: 16px;
            //width: 80px;
        }
        .btn {
            width: 90px;
            font-size: 16px;
            margin-left: 10px;
        }
    }
}
.item-error {
    width: 245px;
    height: 140px;
    background-image: url("../../assets/img/product/default_3.png");
    background-size: cover;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
    padding: 40px 20px;
    color: #FFFFFF;
    overflow: hidden;
}

.pic-box {
    padding: 20px;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    background-color: #FFFFFF;
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    overflow: hidden;
    /*box-shadow: 0 2px 5px rgb(0 0 0 / 8%);*/
}
.pic-box:hover {
    box-shadow: 0 0px 8px rgba(0,0,0,.15);
    transform: translate3d(0,-2px,0);
    cursor: pointer;
}
.pagination {
    position: sticky;
    bottom: 0px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
}
.trades-navs {
    position: fixed;
    left: 0;
    top: 120px;
    height: 73vh;
    z-index: 99;
    .tn-list {
        position: relative;
        width: 9vw;
        //height: 3.94vw;
        margin-bottom: 6px;
        color: #000;
        border: 1px solid #cecece;
        cursor: pointer;
        transition: all .3s ease;
        background: rgba(255, 255, 255, .7);
        &:hover{
            //transform: scale(1.2);
            //transform-origin: bottom;
            //z-index: 10;
            background: rgba(0,0,0,.1);
        }
        .tn-dec {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-left: 10px;
            text-align: center;
            .iconfont {
                font-size: 1.4vw;
                width: 1.8vw;
                margin-right: 10px;
            }
            .tn-name {
                font-size: .8vw;
                font-weight: bold;
            }
        }
    }
    .on {
        //transform: scale(1.2);
        //transform-origin: left;
        background: #15a0ff;
        color: white;
        &:hover{
            //transform: scale(1.2);
            //transform-origin: bottom;
            //z-index: 10;
            background: #15a0ff;
        }
    }
}
.title-name {
    font-size: 2vw;
    font-weight: bold;
    color: white;
    padding: 10px 30px;
    text-shadow: 0 0 20px rgba(0, 0, 0, .1);
}
.content-box {
    width: 1325px;
    margin: 20px auto;
    padding-top: 50px;
    .bs {
        border-radius: 0 10px 10px;
    }
    .company-box {
        width: 100%;
        padding: 10px 30px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0,0,0,.2);
        .icon-box {
            width: 50px;
            height: 50px;
            background: linear-gradient(45deg, #0d9aff, #00d2d9);
            border-radius: 30px;
            color: white;
            .iconfont {
                font-size: 25px;
            }
        }
    }
}
.plan-search {
    margin-top: 30px;
}
.plan-box {
    margin-top: 20px;
}
</style>
