<template>
    <el-dialog
            title="方案详情"
            width="52%"
            top="2vh"
            :visible.sync="visible">
        <div>
            <div class="f24 fwb tac pb-2" style="color: #000">{{ detail.serviceName }}</div>
            <div class="uf uf-ac uf-pc mb-4 mt-3 op-08">
                <i class="iconfont icon-company-fill mr-2" style="font-size: 18px"></i>
                <div class="f16">{{detail.enterpriseName}}</div>
            </div>
            <my-editor-view :content="detail.itemDetail"></my-editor-view>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="visible = false">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
import MyEditorView from '@/components/my-editor-view.vue'
export default {
    components: {
        MyEditorView
    },
    data () {
        return {
            visible: false,
            detail: ''
        }
    },
    methods: {
        init (item) {
            this.visible = true
            item.itemDetail = item.itemDetail || item.productDetail
            item.serviceName = item.serviceName || item.productName
            this.detail = item
        }
    }
}
</script>
<style scoped lang="scss">
    ::v-deep .el-dialog__body {
        padding: 10px 50px;
        .ql-editor {
            overflow: inherit;
        }
    }
</style>
